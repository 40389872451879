import {inject, Injectable, signal} from '@angular/core'
import {HelperService} from '@sparbanken-syd/sparbanken-syd-bankid'
import {Observable, of} from 'rxjs'
import {LOCAL_STORAGE} from '../application/injection-tokens'

export const ACCESS_TOKEN_NAME = 'lr-select-terms-token'

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  /**
   * Used as indication if we are logged in or not
   */
  public accessToken$ = signal<string | null>(null)

  public admin$ = signal<boolean>(false)

  private ils: Storage = inject(LOCAL_STORAGE)

  public logout(): void {
    this.admin$.set(false)
    this.accessToken$.set(null)
    this.ils.removeItem(ACCESS_TOKEN_NAME)
  }

  public setToken(token: string | null): Observable<string | null> {
    const payload = HelperService.GetTokenPayload(token)
    if (payload) {
      this.accessToken$.set(token as string)
      this.admin$.set(payload.roles.indexOf('renewalToolBinding') !== -1)
      this.ils.setItem(ACCESS_TOKEN_NAME, token as string)
    }
    return of(token)
  }
}
